<template>
    <modal ref="rechazar" titulo="Rechazar pedido" icon="rechazar-pedido" @guardar="rechazar_pedido">
        <ValidationObserver ref="form">
            <div class="row mx-0 justify-center">
                <div class="col-10 text-center text-general">
                    Es una lástima que no hayas tomado el pedido, cuéntanos por que tomas esta decisión.
                </div>
            </div>
            <div class="row mx-0 my-3 justify-center">
                <div class="col-10">
                    <label class="text-general f-12 pl-3">Justificación</label>
                    <ValidationProvider v-slot="{ errors }" rules="required" name="justificacion">
                        <el-input v-model="model.justificacion" type="textarea" show-word-limit maxlength="250" :rows="3" />
                        <span class="text-danger f-11">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Pedidos from '~/services/pedidos/pedidos-admin'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            model:{
                id_motivo:null,
                justificacion:'',
            },
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
        }),
    },
    methods:{
        toggle(){
            this.model = {
                id_motivo:null,
                justificacion:''
            }
            this.$refs.rechazar.toggle()
        },
        async rechazar_pedido(){
            try {

                const valid = await this.$refs.form.validate()

                if(valid){
                    this.model.id_pedido = this.id_pedido
                    this.model.estado = 25
                    const {data} = await Pedidos.rechazar_cancelar_tendero(this.model)

                    this.$emit('accion-rechazar')
                    this.notificacion('Mensaje','Pedido rechazado','success')
                    this.$refs.rechazar.toggle()
                }
            } catch (e){
                this.error_catch(e)
            }
        }

    }
}
</script>

<style lang="css" scoped>
</style>
